.Ufit {
  .product-variant-button:hover {
    color: #000000E0;
    border-color: #C1A990;
    background-color: #C1A99033;
  }
  .product-variant-button.active {
    background-color: #C1A990;
  }
  .product-variant-button.not-available {
    background-color: #DEDEDE;
  }
}
