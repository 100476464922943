.mall-banner > .techbase-images-slider {
  .swiper-slide {
    overflow: hidden;
    position: relative;
    height: 100%;
    border-radius: 5px;

    img {
      display: block;
      width: 100%;
      object-fit: cover;
      object-position: center center;
      @media (min-width: 768px) {
        border-radius: 25px;
        height: 430px;
      }
    }
  }
}

.dashboard-banner > .techbase-images-slider {
  .swiper-slide {
    overflow: hidden;
    position: relative;
    height: 100%;
    border-radius: 5px;

    img {
      display: block;
      width: 100%;
      object-fit: cover;
      object-position: center center;
      @media (min-width: 768px) {
        border-radius: 25px;
        height: 430px;
      }
    }
  }
}
